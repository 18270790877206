import React, { useContext } from 'react';
import { type LazySuspenseProps, LazySuspense, WaitContext } from 'react-loosely-lazy';
import { ff } from '@atlassian/jira-feature-flagging';
import useLayoutEffectSAFE from '@atlassian/jira-use-layout-effect-safe';
import UFOInteractionContext from '@atlassian/react-ufo/interaction-context';

function Hold({ name }: { name?: string }) {
	const interactionContext = useContext(UFOInteractionContext);
	const waitContext = useContext(WaitContext);

	// Runs synchronously, to allow us to identify async items
	useLayoutEffectSAFE(() => {
		// if until context exists means there is a LazyWait parent component
		// we use that value
		const shouldHold = waitContext ? !!waitContext.currentValue() : true;
		if (interactionContext != null && shouldHold) {
			return interactionContext.hold(name);
		}
	}, [interactionContext, name, waitContext]);
	return null;
}

/**
 * @deprecated Use {@link @atlassian/react-ufo/placeholder#Placeholder} instead.
 */
export default function Placeholder({
	name,
	children,
	fallback,
}: {
	/**
	 * Named Placeholders will help when analysing the break-down of the page load in Performance Portal.
	 * */
	name: string;
	children?: LazySuspenseProps['children'];
	fallback?: LazySuspenseProps['fallback'];
}) {
	// react-18: can use fallback directly
	const fb = fallback != null ? fallback : null;
	// react-18: can use children directly
	const kids = children != null ? children : null;
	return (
		<LazySuspense
			fallback={
				ff('ufo.placeholder') ? (
					<>
						{fb}
						<Hold name={name} />
					</>
				) : (
					fb
				)
			}
			name={name}
		>
			{kids}
		</LazySuspense>
	);
}
