import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logEmauForJira = (subproduct: string, url: any, urlParams: any) => {
	const body = JSON.stringify({
		eventData: { url, subproduct, urlParams },
	});

	const fetchOptions = {
		...defaultOptions,
		method: 'POST',
		body,
	};
	const endpointUrl = '/rest/internal/2/log/unsafe/emau';

	return fetch(endpointUrl, fetchOptions);
};

export const logEmau = ({ product, subproduct }: { product: string; subproduct: string }) => {
	if (product === 'jira') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		logEmauForJira(subproduct, window.location.pathname, window.location.search);
	}
};
