import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import type { ExtraNavigationTimings, PageSizeMetrics } from './types';
/**
 * Calculates and returns metrics regarding the size of the page, including the total number of DOM elements,
 * the maximum depth of nested elements, and the maximum number of child elements in a single parent.
 * This is useful for analyzing the complexity and performance implications of the DOM structure.
 */
export const getPageSizeMetrics = (): PageSizeMetrics => {
	let maxDepth = -1;
	let maxWidth = -1;
	let numElements = 0;

	// As described in the lighthouse DOM audit - https://web.dev/dom-size/

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const calculateDOMsizeAndDepth = (el = document.body, depth = 1) => {
		if (!el) {
			return;
		}
		if (depth > maxDepth) {
			maxDepth = depth;
		}
		if (el.children.length > maxWidth) {
			maxWidth = el.children.length;
		}
		let child = el.firstElementChild;
		while (child) {
			// @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
			calculateDOMsizeAndDepth(child, depth + 1);
			// If element has shadow dom, traverse into that tree.
			if (child.shadowRoot) {
				// @ts-expect-error - TS2345 - Argument of type 'ShadowRoot' is not assignable to parameter of type 'HTMLElement'.
				calculateDOMsizeAndDepth(child.shadowRoot, depth + 1);
			}
			child = child.nextElementSibling;
			numElements += 1;
		}
	};
	calculateDOMsizeAndDepth();
	return {
		totalBodyElements: numElements,
		maxNestedLevels: maxDepth,
		maxChildElements: maxWidth,
	};
};
export const getExtraNavigationTimings = (): ExtraNavigationTimings => {
	if (!performance) return {};
	const navigation = performance.getEntriesByType('navigation')[0];
	if (!navigation) return {};
	return {
		// From https://www.w3.org/TR/navigation-timing-2/
		// @ts-expect-error - TS2339 - Property 'decodedBodySize' does not exist on type 'PerformanceEntry'.
		decodedBodySize: navigation.decodedBodySize,
		// @ts-expect-error - TS2339 - Property 'encodedBodySize' does not exist on type 'PerformanceEntry'.
		encodedBodySize: navigation.encodedBodySize,
		// @ts-expect-error - TS2339 - Property 'transferSize' does not exist on type 'PerformanceEntry'.
		transferSize: navigation.transferSize,
	};
};
const assetsTypes = ['script', 'link'];
/**
 * Provides a comprehensive summary of page size metrics, including total, encoded, and decoded sizes,
 * for both the overall page and its individual resources. Aimed at facilitating a deeper understanding
 * of page load performance and resource impact.
 */
export const getPageSizeInfo = ({ start, stop }: { start: number; stop: number }) => {
	const pageSize = getExtraNavigationTimings();
	if (
		!pageSize ||
		Object.keys(pageSize).length === 0 ||
		pageSize.decodedBodySize === 0 ||
		start > 0
	) {
		return {};
	}
	const decoded: Record<string, number> = {
		total: pageSize.decodedBodySize,
		pageSize: pageSize.decodedBodySize,
		assets: 0,
		endpoints: 0,
	};
	const encoded: Record<string, number> = {
		total: pageSize.encodedBodySize,
		pageSize: pageSize.encodedBodySize,
		assets: 0,
		endpoints: 0,
	};
	const net: Record<string, number> = {
		total: pageSize.transferSize,
		assets: 0,
		endpoints: 0,
	};

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	for (const entry of performance.getEntriesByType('resource') as PerformanceResourceTiming[]) {
		if (entry.startTime > stop) {
			// eslint-disable-next-line no-continue
			continue;
		}
		if (assetsTypes.includes(entry.initiatorType)) {
			decoded.total += entry.decodedBodySize;
			decoded.assets += entry.decodedBodySize;
			encoded.total += entry.encodedBodySize;
			encoded.assets += entry.encodedBodySize;
			net.total += entry.transferSize;
			net.assets += entry.transferSize;
		} else {
			decoded.total += entry.decodedBodySize;
			decoded.endpoints += entry.decodedBodySize;
			encoded.total += entry.encodedBodySize;
			encoded.endpoints += entry.encodedBodySize;
			net.total += entry.transferSize;
			net.endpoints += entry.transferSize;
		}
	}
	const returnValue: Record<string, number> = {};
	Object.keys(decoded).forEach((key) => {
		returnValue[`size:decoded:${key}`] = decoded[key];
	});
	Object.keys(encoded).forEach((key) => {
		returnValue[`size:encoded:${key}`] = encoded[key];
	});
	Object.keys(net).forEach((key) => {
		returnValue[`size:net:${key}`] = net[key];
	});
	return returnValue;
};
